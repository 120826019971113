// Runtime webpack config
import '../../other/WebpackConfig';

import '../../../scss/homepage.mp.nlnl.scss';

// i18n
import i18n from '../../i18n/nl-NL';

import homepage from './homepage';

homepage(i18n);
